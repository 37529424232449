<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos de la ruleta" ref="pageForm" :items="items" :isAdmin="isItForAdmin()">
      <template>
        <PageForm :typeform="typeform" :crupiers="crupiers" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body"></div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageForm from "./form.vue";
import PageHeader from "@/components/page-header";
import { breadCrumbData } from "@/helpers/breadcrumbs/breadCrumbs";

export default {
  components: { Layout, PageHeader, PageForm },
  data() {
    return {
      title: "Tigre Dragón - Mesa Fisica",
      items: breadCrumbData.tigerDragon,
      typeform: {
        name: "",
        providerId: "",
      },
      userData: "",
      userRole: "",
      crupiers: [],
    };
  },
  methods: {
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;
    },
    isItForAdmin() {
      return ["ADMIN"].includes(this.userRole);
    },
    getData() {
      this.getUserRole();
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: "",
      };

      // this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
